import React from "react";
import styled from "styled-components";
// Components
// Assets
import AddImage1 from "../../assets/img/role_to_youre_choice.avif";
import AddImage2 from "../../assets/img/AI_power.jpg";
import AddImage3 from "../../assets/img/time_saving2.jpg";
import AddImage4 from "../../assets/img/role_specific.jpg";
import AddImage5 from "../../assets/img/image-cp-1.jpg";
import AddImage6 from "../../assets/img/image-cp-2.jpg";
import AddImage7 from "../../assets/img/image-cp-3.jpg";
import AddImage8 from "../../assets/img/image-cp-5.jpg";
import AddImage9 from "../../assets/img/image-cp-4.jpg";
import AddImage10 from "../../assets/img/image-cp-6.jpg";

import FullButton from "src/components/Buttons/FullButton";
import ServiceBox from "src/components/Elements/ServiceBox";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Iconify from "src/components/iconify";
import { Link } from "react-scroll";
import { useRouter } from "src/routes/hooks";
import HeaderImage from "../../assets/img/BoyInterview.jpg";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Services() {
  const [value, setValue] = React.useState(0);
  const router = useRouter()
  const mediaQueryList = window.matchMedia("(max-width: 700px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div >
      <Wrapper id="about">

        <div className="lightBg" style={{ background: "#2f5190", position: "relative" }}>
          <div className="context">
            <div className="container">

              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                <div>
                  <Typography variant="h3" color={'#fff'}>Welcome to Capstone Interviewer</Typography>
                  <Typography variant="h6" mb={5} color={'#fff'}>Redefining the Future of Interviews </Typography>

                  {/* <video width={!mediaQueryList?.matches ? "600" : "auto"} controls src="https://www.youtube.com/watch?v=JEAWDE-rnN8" style={{ borderRadius: 15 }} >

                </video> */}
                  <p style={{ color: 'white' }}>
                    Capstone Interviewer is an innovative dual-sided platform designed to meet the evolving needs of today’s job seekers and hiring managers. Powered by cutting-edge AI technology and driven by the goal of creating seamless connections between talent and opportunity, our platform transforms the way interviews are prepared for, conducted, and evaluated.
                  </p>
                  <p style={{ color: 'white', margin: '25px 0px' }}>
                    Whether you’re a job seeker aiming to secure your dream role or a hiring manager focused on building the best team, Capstone Interviewer empowers you with the tools and insights to succeed.
                  </p>
                  <p style={{ color: 'white' }}>
                    Our mission is simple yet powerful: to make interviews smarter, faster, and more effective for everyone. With customized solutions, automated processes, and actionable feedback, we ensure that every interaction is meaningful, efficient, and impactful.
                  </p>
                </div>
              </Box>
            </div>

          </div>
          <div className="area" >
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div >


        </div>
        <div className="lightBg"
          style={{ padding: "60px 0" }}
        >
          <div className="whiteBg" >
            <div className="container" >
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h2 className="font30 extraBold">For Job Seekers</h2>
                  <h4 className="font15 semiBold ">In today’s competitive job market, landing your dream job requires more than just a polished resume—it demands confidence, strategy, and the ability to showcase your unique value. Capstone Interviewer gives you the edge you need with: </h4>
                  <p className="font12" style={{ marginTop: "10px" }}>
                    <ul style={{ listStyleType: "revert" }}>
                      <li>
                        <strong>Unlimited Expert Feedback for Roles of Your Choice : </strong> Practice as much as you need with unlimited access to AI-powered and human-reviewed feedback tailored to specific roles or industries. Whether you're preparing for tech, healthcare, or business positions, our platform adapts to your unique career aspirations.
                      </li>
                      <li>
                        <strong>AI-Powered Analysis to Fine-Tune Your Skills : </strong> Receive detailed insights on your speaking quality, confidence, pace, and facial expressions to identify areas for improvement. Our cutting-edge tools ensure you’re fully prepared to make the best impression.
                      </li>
                      <li>
                        <strong>Role-Specific Simulations : </strong> Engage with realistic interview scenarios customized to the roles you’re targeting. These simulations prepare you for common questions and industry-specific challenges, ensuring you’re never caught off guard.
                      </li>
                      <li>
                        <strong>Time-Saving and Flexible Preparation : </strong> With Capstone Interviewer, you can practice on your schedule, saving hours of time compared to traditional coaching methods. Achieve job-readiness faster and more efficiently than ever.
                      </li>
                    </ul>
                  </p>

                  <p className="font12" style={{ marginTop: "10px" }}> <strong>Your Key Value Proposition : </strong> Unlimited expert feedback, tailored simulations, and precise insights ensure you’re equipped to excel in any interview setting. Get ready to stand out and secure your dream job! </p>
                </AddLeft>


                <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src={AddImage1} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src={AddImage2} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter" style={{ marginTop: "20px" }}>
                      <AddImgWrapp3>
                        <img src={AddImage3} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src={AddImage4} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </div>
          </div>

          <div className="whiteBg landingPageHiringPara">
            <div className="container">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h2 className="font30 extraBold">For Hiring Managers </h2>
                  <h4 className="font15 semiBold ">Finding the right talent for your organization is critical, but early-round candidate screening can be time-consuming and inconsistent. Capstone Interviewer transforms the hiring process with tools designed for efficiency and accuracy. </h4>
                  <p className="font12" style={{ marginTop: "10px" }}>
                    <ul style={{ listStyleType: "revert" }}>
                      <li>
                        <strong>Automate Early Round Screening : </strong> Reduce your time spent on initial candidate evaluations by up to <strong>70%</strong>. Capstone Interviewer uses AI-driven analysis to evaluate communication skills, confidence, and response relevance in a fraction of the time it takes for traditional interviews.
                      </li>
                      <li>
                        <strong>Customizable Candidate Evaluation Criteria : </strong> Tailor screening processes to your organization’s unique needs by setting specific criteria for skills, attributes, and qualifications. Identify the best fits faster without sacrificing quality or thoroughness.
                      </li>
                      <li>
                        <strong>Data-Driven Insights for Confident Hiring Decisions : </strong> Receive comprehensive candidate reports that highlight strengths, improvement areas, and overall suitability for the role. With up to <strong>50% fewer post-interview follow-ups</strong> needed, you can make confident hiring decisions with actionable data at your fingertips.
                      </li>
                      <li>
                        <strong>Reduce Time-to-Hire : </strong> On average, companies using Capstone Interviewer cut their hiring timeline by two weeks or more, enabling them to secure top talent before competitors.
                      </li>
                      <li>
                        <strong>Enhance Cost Efficiency : </strong> By automating repetitive tasks, you can reallocate team resources to high-impact activities, reducing costs associated with manual early-stage interviews by <strong> up to 30%</strong>.
                      </li>
                      <li>
                        <strong>Elevate the Candidate Experience : </strong> Provide candidates with a seamless, professional, and efficient interview process. With clearer expectations and faster turnaround times, your organization leaves a positive and lasting impression.
                      </li>
                    </ul>
                  </p>
                  <p className="font12" style={{ marginTop: "10px", }}> <strong>Your Key Value Proposition : </strong> Save <strong>70% of time</strong> on early-round screenings, reduce time-to-hire by <strong>two weeks or more</strong>, and build stronger teams with fewer resources. Capstone Interviewer empowers you to hire smarter, faster, and better.</p>
                </AddLeft>

                <AddRight >
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src={AddImage5} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src={AddImage7} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter" style={{ marginTop: "20px" }}>
                      <AddImgWrapp3>
                        <img src={AddImage6} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src={AddImage8} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </div>
          </div>

          <div className="container tabsforWhy" >
            <HeaderInfo >
              <Box display={"flex"} flexDirection={'column'} alignItems={'center'} py={5}>
                <Typography variant="h3" mb={1} color={'#000'} textAlign={'center'}>Why Capstone Interviewer? </Typography>
                <Typography variant="h6" mb={5} textAlign={'center'} width={"80%"}>At Capstone Interviewer, we bridge the gap between job seekers and hiring managers, providing unmatched tools and resources to create meaningful professional connections. </Typography>
              </Box>
            </HeaderInfo>
            <ServiceBoxRow className="flex home-tabs" style={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ width: '100%', textAlign: "center" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="For Job Seekers"  {...a11yProps(0)} />
                    <Tab label="For Hiring Managers"  {...a11yProps(0)} />
                    {/* <Tab label="Personalized Feedback on Facial Sentiment"  {...a11yProps(0)} />
                    <Tab label="Boost Your Confidence"  {...a11yProps(1)} />
                    <Tab label="Enhance Speaking Quality"{...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
                <div className="background-4">
                  <CustomTabPanel value={value} index={0}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ borderRadius: 4, color: "#fff" }}>
                          <Img className="radius8" src={AddImage10} alt="office" style={{ zIndex: 9, height: 300, width: 'auto' }} />
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={"1%"} py={"2%"} fontWeight={500}>
                          Build confidence, save time, and prepare effectively for any interview.</Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ borderRadius: 4, color: "#fff" }}>
                          <Img className="radius8" src={AddImage9} alt="office" style={{ zIndex: 9, height: 300, width: 'auto' }} />
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Streamline hiring, reduce time-to-decision, and make data-backed talent choices.</Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ borderRadius: 4, color: "#fff" }}>
                          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, height: 300, width: 'auto' }} />
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Understand how your expressions are perceived and make improvements to convey the right emotions during your interview.</Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4 }}>
                          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, height: 300, width: 'auto' }} />
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Receive constructive feedback to help you speak confidently and make a lasting impression on your interviewers.
                        </Typography>
                      </Grid>

                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={4}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Get insights on your speaking pace, clarity, and tone, ensuring your communication is clear and effective.
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4 }}>
                          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9, height: 300, width: 'auto' }} />
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={5}>
                    <Grid container alignItems={'center'}>
                      <Grid item sm={12} md={7} lg={7}>
                        <Typography variant="h6" px={5} py={2} fontWeight={500}>
                          Learn how to structure and deliver the best answers to common medical school interview questions, increasing your chances of success.
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={5} lg={5}>
                        <Box sx={{ background: "#f2f2f2", borderRadius: 4, padding: 20 }}>
                          <p>Image</p>
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </div>
              </Box>

            </ServiceBoxRow>
          </div>
        </div>

      </Wrapper>
      <div className="container " style={{ padding: "90px 20px 90px 20px", background: 'white', }}>
        <HeaderInfo style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ textAlign: "center", maxWidth: '70%' }}>  Whether you’re searching for your dream role or your next great hire, Capstone Interviewer is here to make the process seamless, efficient, and rewarding.</p>
          <br />
          <br />
          <Typography variant="h3" mb={2} color={'#000'} textAlign={'center'}>Let us help you achieve your goals</Typography>
          <Typography variant="h6" color={'#000'} textAlign={'center'}> start your journey today with Capstone Interviewer! </Typography>
        </HeaderInfo>
      </div>

      {/* <div className="container " style={{ padding: "60px 20px 0px 20px", background: 'white' }}>
        <HeaderInfo>
          <Typography variant="h3" color={'#000'} textAlign={'center'}>Our Product</Typography>
          <br />
          <br />
          <p>  Welcome to Capstone Interviewer, your ultimate solution for medical school interview preparation. Our innovative tool is designed to provide you with a comprehensive and immersive interview practice experience, ensuring you are well-prepared to excel in your medical school interviews.</p>
        </HeaderInfo>
        <ServiceBoxRow className="flex home-tabs" style={{ display: "flex", justifyContent: "center", padding: '0px 100px' }}>
          <ServiceBoxWrapper style={{ justifyContent: 'center' }}>
            <ServiceBox
              icon="roller"
              title="Tailored Interview Simulation"
              subtitle="Experience realistic simulations of medical school interviews with a large pool of commonly asked questions."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="monitor"
              title="Personalized Feedback"
              subtitle="Receive crucial feedback on your verbal and non-verbal communication skills, enhancing your overall interview performance"
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="browser"
              title="Advanced Analysis"
              subtitle="Benefit from advanced algorithms that analyze facial expressions, sentiments, and speech quality, providing real-time feedback for improvement."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox icon="printer" title="Optimal Answer Comparison" subtitle="Compare your responses with optimal answers through side-by-side comparisons, highlighting strengths and suggesting improvements." />
          </ServiceBoxWrapper>

        </ServiceBoxRow>
      </div> */}
    </div >
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 90%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 50%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 50%;
  margin-left: 10%;
  margin-top:6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 50%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 30%;
    height: 30%;
  }
`;