import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'practice',
    icon: icon('video-plus'),
    items: [
      {
        title: 'Interview',
        path: '/interview',
        icon: icon('question-answer'),
      },
      {
        title: 'Uploads',
        path: '/upload',
        icon: icon('upload'),
      }
    ]
  },
  {
    title: 'QA Videos',
    path: '/question-answer-video',
    icon: icon('question-answer'),
  },
  {
    title: 'Library',
    path: '/library',
    icon: icon('video'),
  },
  {
    title: 'Scheduled Interview',
    path: '/scheduled-interview',
    icon: icon('video'),
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: icon('setting'),
  },

  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];
const adminNavConfig = [
  // {
  //   title: 'admin-dashboard',
  //   path: '/',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Users',
    path: '/',
    icon: icon('users'),
  },
  {
    title: 'Questions',
    path: '/question-answer',
    icon: icon('question-answer'),
  },
  {
    title: 'Prompt',
    path: '/prompt',
    icon: icon('prompt'),
  },

];

export { navConfig, adminNavConfig };

